// Section Article
.articles-blog {
  position: relative;

  // Pattern Settings
  .pattern {
    z-index: -1;
    position: absolute;
    background-size: contain;
    background-position: center;
    width: 1714px;
    aspect-ratio: 2.63 / 1;
    top: toRem(91);

    &--1 {
      background-image: url('../../media/images/patterns-and-ornaments/blog-v1-pattern-articles.svg');
      right: 0;
    }

    &--2 {
      background-image: url('../../media/images/patterns-and-ornaments/blog-v2-pattern-articles.svg');
    }
  }

  .container {
    padding: toRem(80) 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(md) {
      padding: toRem(64) 0;
    }

    @include media-breakpoint-down(sm) {
      padding-inline: toRem(20);
    }

    // Header
    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      margin-bottom: toRem(64);

      @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: center;
        justify-content: unset;
        gap: toRem(56);
        margin-bottom: toRem(48);
      }

      h2 {
        color: $color-dark-deep-blue;
      }

      .nav-wrap {
        @include media-breakpoint-down(xs-3) {
          overflow-x: auto;
          margin-inline: toRem(-20);
          align-self: stretch;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }

      .blog-nav {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: toRem(16);
        flex-wrap: nowrap;

        @include media-breakpoint-down(md) {
          width: max-content;
          padding-inline: toRem(20);
        }

        button {
          color: $color-dark-blue;
          padding: toRem(8) toRem(24);
          border: 1px solid $color-dark-blue;
          border-radius: 10px;

          &.active {
            background-color: $color-dark-blue;
            color: $color-white;
          }
        }
      }
    }

    // Content
    .content {
      width: 100%;
      margin-bottom: toRem(48);

      .tab-pane {
        &.active {
          display: flex;
          row-gap: toRem(48);
        }

        .col-4 {
          padding-inline: toRem(10);
        }
      }
    }
  }
}
